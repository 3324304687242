import { Base } from "../base";
import { Uploader } from "../../lib/file_uploader";

export class Users__Reports extends Base {
  edit() {
    window.filesUploading = [];
    this.initEventUpload();
    this.initButtonGoBack();
  }

  initEventUpload() {
    const inputs = document.querySelectorAll(".multi-direct-upload");
    inputs.forEach(function (input) {
      input.addEventListener("change", (event) => {
        Array.from(input.files).forEach((file) => uploadFile(file));
        input.value = null;
      });
      const uploadFile = (file) => {
        const upload = new Uploader(file, input);
        window.filesUploading.push(upload?.upload?.id);
        upload.start();
      };
    });
  }

  initButtonGoBack() {
    const button = document.querySelector("#btn_go_back");
    button.addEventListener("click", (event) => {
      event.preventDefault();
      window.redirectPage.go(
        "/users/projects/" + button.getAttribute("data-id"),
        function () {
          window.App.activeTabPage("tab_active_report");
        }
      );
    });
  }
}
