export class Base {
  constructor(action) {
    if (typeof this["common"] === "function") {
      this["common"]();
    }
    if (typeof this[action] === "function") {
      this[action]();
    }
  }
}
