import { Base } from "../base";

export class Admins__Projects extends Base {
  show() {
    this.initBoostCheckbox();
    this.initChangeStatusModal();
    this.initButtonAddField();
    this.initButtonRemoveField();
  }

  initBoostCheckbox() {
    const boostInfo = document.querySelector("#boost-info");
    document
      .querySelector("#project_has_boost")
      .addEventListener("change", (e) => {
        if (e.currentTarget.checked) {
          boostInfo.classList.remove("d-none");
          boostInfo.classList.add("d-block");
        } else {
          boostInfo.classList.remove("d-block");
          boostInfo.classList.add("d-none");
          document.querySelector("#btn_submit").click();
        }
      });
  }

  initButtonAddField() {
    if (document.querySelectorAll('.nested-fields').length == 3) {
      document.querySelector(".add_fields").classList.remove("d-block");
      document.querySelector(".add_fields").classList.add("d-none");
    }
    $(".add_fields").off("click");
    $(".add_fields").on("click", function (e) {
      e.preventDefault();
      const link = e.currentTarget;
      const buildId = link.getAttribute("data-id-build");
      let customHtml = "";
      // Save a unique timestamp to ensure the key of the associated array is unique.
      let time = new Date().getTime();
      if (buildId) {
        let orgHtml = document.querySelector(
          `div[data-build-id='${buildId}']`
        ).innerHTML;

        // replace
        orgHtml = orgHtml.replaceAll(`data-is-org="${buildId}"`, "");
        orgHtml = orgHtml.replaceAll();
        customHtml = orgHtml
          .replaceAll("_0_", `_${time}_`)
          .replaceAll("[0]", `[${time}]`);
        //
      }

      // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
      let linkId = link.dataset.id;
      // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
      let regexp = linkId ? new RegExp(linkId, "g") : null;
      // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
      let newFields = customHtml
        ? customHtml
        : regexp
        ? link.dataset.fields.replace(regexp, time)
        : null;
      // Add the new markup to the form if there are fields to add.
      newFields ? link.insertAdjacentHTML("beforebegin", newFields) : null;

      if (document.querySelectorAll('.nested-fields').length == 3) {
        document.querySelector(".add_fields").classList.remove("d-block");
        document.querySelector(".add_fields").classList.add("d-none");
      }

      window.App.initButtonRemoveField();
    });
  }

  initButtonRemoveField() {
    document.querySelectorAll(".remove_fields").forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        // Find the parent wrapper for the set of nested fields.
        let fieldParent = link.closest(".nested-fields");
        // If there is a parent wrapper, find the hidden delete field.
        let deleteField = fieldParent
          ? fieldParent.querySelector('input[type="hidden"]')
          : null;
        // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
        if (deleteField) {
          deleteField.value = 1;
        }
        fieldParent.style.display = "none";
        fieldParent.setAttribute("is_remove", 1);

        if (document.querySelectorAll('.nested-fields').length < 3) {
          document.querySelector(".add_fields").classList.remove("d-none");
          document.querySelector(".add_fields").classList.add("d-block");
        }
      });
    });
  }

  initChangeStatusModal() {
    document.querySelectorAll(".btn-change-status").forEach((btn) => {
      btn.addEventListener("click", (e) => {
        const status = btn.getAttribute("data-status");
        const confirmText = document.querySelector("#change-confirmt-text");
        const submitBtn = document.querySelector("#change-btn-submit");
        if (status === "waiting_public") {
          confirmText.innerHTML = "このプロジェクトを承認し、公開待ちに設定しますか？";
          submitBtn.innerHTML = "公開待ちにする";
          submitBtn.value = "公開待ちにする";
        } else if (status === "rejected") {
          confirmText.innerHTML = "このプロジェクトをリジェクトしますか？";
          submitBtn.innerHTML = "リジェクトする";
          submitBtn.value = "リジェクトする";
        } else if (status === "reviewing") {
          confirmText.innerHTML = "このプロジェクトを審査待ちの状態に戻しますか？";
          submitBtn.innerHTML = "審査待ちにする";
          submitBtn.value = "審査待ちにする";
        } else if (status === "suspend") {
          confirmText.innerHTML = "このプロジェクトを公開停止しますか？";
          submitBtn.innerHTML = "公開停止する";
          submitBtn.value = "公開停止する";
        } else if (status === "published_inprogress") {
          confirmText.innerHTML = "このプロジェクトを再公開すしますか？";
          submitBtn.innerHTML = "再公開する";
          submitBtn.value = "再公開する";
        }

        document.querySelector("#project_status").value = status;
      });
    });
  }
}
