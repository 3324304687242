import { Base } from "../base";
import { Modal } from "bootstrap";
import Rails from "@rails/ujs";
import { csrfToken } from "@rails/ujs";

export class Users__Rewards extends Base {
  show() {
    // $.notify("I'm to the right of this box", {
    //   position: "top center",
    //   className: "success alert alert-success",
    //   hideDuration: 50000,
    //   autoHideDelay: 500000,
    //   gap: 20,
    //   arrowSize: 10,
    //   style: "bootstrap",
    // });
    console.log("Users__Rewards#show");
    this.initInputPostcode();
    this.initEventSelectAddress();
    this.removeAddresses();
    $(".answer-question").on("change", (e) => {
      let questionAnswer
      $(".answer-question:checked").each(function(index) {
        if(index == 0) {
          questionAnswer = $(this).val();
        } else {
          questionAnswer += "," + $(this).val();
        }
      });
      $("#reward_answer").val(questionAnswer)
    });
  }

  /**
   * It adds an event listener to the input element with the class name "input_post_code" and when the
   * user types in a 7 digit number, it fetches the data from the API and fills in the other input
   * elements with the data
   */
  initInputPostcode() {
    const input = document.querySelector(".input_post_code");
    if (input) {
      input.addEventListener("input", async (event) => {
        const value = event.target.value;
        if (value && value.length == 7) {
          const res = await fetch(
            "https://madefor.github.io/postal-code-api/api/v1/" +
              value.slice(0, 3) +
              "/" +
              value.slice(3, 7) +
              ".json"
          );
          const data = await res.json();
          if (data && data.data && data.data.length > 0) {
            const postCodeJa = data.data[0].ja;
            const prefecture = postCodeJa.prefecture;
            // document.querySelector(".select-pref").value = "神奈川県";
            $(".select-pref").val(prefecture).trigger("change", true);
            const city = postCodeJa.address1;
            document.querySelector(".input_city").value = city;
            const state = postCodeJa.address2;
            document.querySelector(".input_state").value = state;
          }
        }
      });
    }
  }

  /**
   * It takes the values of the inputs and selects and puts them in the hidden inputs
   */
  initEventSelectAddress() {
    const button = document.querySelector("#btn_select_address");
    if (button) {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        if (this.validateFormAddress()) {
          const params = {
            name: document.querySelector(".input_name").value,
            post_code: document.querySelector(".input_post_code").value,
            pref: document.querySelector(".select-pref").value,
            city: document.querySelector(".input_city").value,
            state: document.querySelector(".input_state").value,
            other: document.querySelector(".input_other").value,
            phone: document.querySelector(".input_phone").value,
            country: document.querySelector(".select-country").value,
            id: document.querySelector(
              "#reward_supporters_attributes_0_address_id"
            ).value,
          };
          const res = await fetch("/users/rewards/create_address", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": Rails.csrfToken(),
            },
            body: JSON.stringify({
              address: params,
            }),
          });
          const data = await res.json();
          const address = data.address;
          if (address) {
            document.querySelector(
              "#reward_supporters_attributes_0_address_id"
            ).value = address.id;
            document.querySelector(".btn_close_modal").click();
            document.querySelector("#btn_submit").disabled = false;
            const divNoneAddress = document.querySelector(".none-address");
            if (divNoneAddress) {
              divNoneAddress.classList.add("d-none");
            }
            const divAddress = document.querySelector(".has-address");
            if (divAddress) {
              divAddress.classList.remove("d-none");
              document.querySelector(".label_name").textContent = address.name;
              const postCode = address.post_code;
              document.querySelector(".label_post_code").textContent =
                "〒" + postCode.slice(0, 3) + "-" + postCode.slice(3, 7);
              const fullAddress =
                address.pref + address.city + address.state + address.other;
              document.querySelector(".label_full_address").textContent =
                fullAddress;
              document.querySelector(".label_phone").textContent =
                address.phone;
            }
          }

          // const inputs = document.querySelectorAll(".input-event");
          // inputs.forEach((input) => {
          //   const value = input.value;
          //   const name = input.getAttribute("data");
          //   const inputHidden = document.querySelector(".input_hidden_" + name);
          //   if (inputHidden) {
          //     inputHidden.value = value;
          //   }
          // });
          // const selects = document.querySelectorAll(".select-event");
          // selects.forEach((select) => {
          //   const value = select.value;
          //   const name = select.getAttribute("data");
          //   const inputHidden = document.querySelector(".input_hidden_" + name);
          //   if (inputHidden) {
          //     inputHidden.value = value;
          //   }
          // });
          // const divNoneAddress = document.querySelector(".none-address");
          // if (divNoneAddress) {
          //   divNoneAddress.classList.add("d-none");
          // }
          // const divAddress = document.querySelector(".has-address");
          // if (divAddress) {
          //   divAddress.classList.remove("d-none");
          //   document.querySelector(".label_name").textContent =
          //     document.querySelector(".input_hidden_name").value;
          //   const postCode =
          //     document.querySelector(".input_hidden_post_code").value || "";
          //   document.querySelector(".label_post_code").textContent =
          //     "〒" + postCode.slice(0, 3) + "-" + postCode.slice(3, 7);
          //   const fullAddress =
          //     document.querySelector(".input_hidden_pref").value +
          //     document.querySelector(".input_hidden_city").value +
          //     document.querySelector(".input_hidden_state").value +
          //     document.querySelector(".input_hidden_other").value;
          //   document.querySelector(".label_full_address").textContent =
          //     fullAddress;
          //   document.querySelector(".label_phone").textContent =
          //     document.querySelector(".input_hidden_phone").value;
          // }
          // document.querySelector(".btn_close_modal").click();
        } else {
          console.log("invalid");
        }
      });
    }
  }

  validateFormAddress() {
    // clear error message
    const errorMessages = document.querySelectorAll(".div-error");
    errorMessages.forEach((errorMessage) => {
      errorMessage.classList.add("d-none");
      errorMessage.classList.remove("invalid-feedback");
    });
    const inputs = document.querySelectorAll(".input-event");
    inputs.forEach((input) => input.classList.remove("input-error"));
    let isValid = true;
    inputs.forEach((input) => {
      if (!input.value) {
        isValid = false;
        input.classList.add("input-error");
        const parent = input.closest(".form-group");
        if (parent) {
          const divError = parent.querySelector(".div-error");
          if (divError) {
            divError.classList.remove("d-none");
            divError.classList.add("invalid-feedback");
          }
        }
      }
    });
    const selects = document.querySelectorAll(".select-event");
    selects.forEach((select) => select.classList.remove("input-error"));
    selects.forEach((select) => {
      if (!select.value) {
        isValid = false;
        select.classList.add("input-error");
        const parent = select.closest(".form-group");
        if (parent) {
          const divError = parent.querySelector(".div-error");
          if (divError) {
            divError.classList.remove("d-none");
            divError.classList.add("invalid-feedback");
          }
        }
      }
    });
    return isValid;
  }

  removeAddresses() {
    const link = document.querySelector(".link_remove_address");
    if (link) {
      link.addEventListener("click", async (e) => {
        e.preventDefault();
        const res = await fetch("/users/rewards/remove_address", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": Rails.csrfToken(),
          },
          body: JSON.stringify({
            id: document.querySelector(
              "#reward_supporters_attributes_0_address_id"
            ).value,
          }),
        });
        const data = await res.json();
        if (data.status === "success") {
          document.querySelector(
            "#reward_supporters_attributes_0_address_id"
          ).value = "";
          const divNoneAddress = document.querySelector(".none-address");
          if (divNoneAddress) {
            divNoneAddress.classList.remove("d-none");
          }
          const divAddress = document.querySelector(".has-address");
          if (divAddress) {
            divAddress.classList.add("d-none");
          }
        }
      });
    }
  }
}

