document.addEventListener("turbolinks:load", function () {
  window.showErrorMessages = new showErrorMessage();
});

import { snakeCase } from "./camel_case";
// エラーメッセージ表示のクラス

const CUSTOM_MESSAGES = ["上記から項目を選択してください"];
class showErrorMessage {
  /**
   * 各インプットでエラーメッセージを表示する
   * @param {Object} attributes
   * @param {String} modelName
   */
  showErrorOnInput(attributes, modelName, withNotify = true, clearMsg = true) {
    if (clearMsg) this.clearMessages();
    modelName = snakeCase(modelName);
    let dataError = [];
    Object.entries(attributes).forEach(([key, value]) => {
      let attributeName = I18n.t(`activerecord.attributes.${modelName}.${key}`);
      if (attributeName.includes("missing")) {
        attributeName = I18n.t(`activerecord.attributes.${modelName}`)[`${key}`]
          ? I18n.t(`activerecord.attributes.${modelName}`)[`${key}`]
          : key;
      }
      if (CUSTOM_MESSAGES.includes(value[0])) {
        attributeName = "";
      }
      dataError.push({
        attributeName: key,
        attributeNameText: attributeName,
        attributeNameTextFull: attributeName + value[0],
        message: value[0],
      });
    });
    console.log("🚀 ~ dataError", dataError);
    const inputsValid = document.querySelectorAll("[data-validate-name]");
    inputsValid.forEach((element) => {
      const nodeName = element.nodeName;
      let hasValue = false;
      if (nodeName === "INPUT") {
        hasValue = element.value ? true : false;
      } else {
        const parent = element.closest(".input-group-validate");
        if (parent) {
          const input = parent.querySelector(".input-validate");
          if (input) hasValue = input.value ? true : false;
        }
      }
      let attributeName = element.getAttribute("data-validate-name");
      const errorInfo = dataError.find((data) => {
        return data.attributeName == attributeName;
      });
      if (errorInfo || !hasValue) {
        let parentDiv = element.parentElement;
        if (element.getAttribute("data-validate-position") == "free") {
          parentDiv = element.parentElement;
        } else {
          parentDiv = element.closest(".input-group-validate");
        }
        if (parentDiv && errorInfo) {
          element.classList.add("is-invalid");
          element.classList.add("input-error");
          let divMsg = document.createElement("div");
          divMsg.classList.add("invalid-feedback");
          divMsg.classList.add("text-red-6");
          divMsg.innerText = errorInfo.attributeNameTextFull;
          parentDiv.appendChild(divMsg);
        }
      }
    });
    // Scroll to top
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  clearMessages() {
    // clear validation
    document.querySelectorAll(".invalid-feedback").forEach((el) => {
      el.remove();
    });
    document.querySelectorAll(".is-invalid").forEach((el) => {
      el.classList.remove("is-invalid");
    });
    document.querySelectorAll(".input-error").forEach((el) => {
      el.classList.remove("input-error");
    });
  }
}
