import { DirectUpload } from "@rails/activestorage";

export class Uploader {
  constructor(file, input) {
    this.fileInputField = input;
    this.idPrefix = `${input.id}_`;
    const url = input.dataset.directUploadUrl;
    this.upload = new DirectUpload(file, url, this);
  }

  start() {
    const { id, file } = this.upload;
    const filesListName = `file-name upload-files-list ${this.idPrefix}upload-files-list`;
    let filesList =
      this.fileInputField.form.getElementsByClassName(filesListName)[0];
    if (!filesList) {
      filesList = document.createElement("ul");
      filesList.className = filesListName;
      this.fileInputField.parentNode.insertBefore(
        filesList,
        this.fileInputField
      );
    }

    filesList.insertAdjacentHTML(
      "beforeend",
      `
      <li id="${this.idPrefix}upload_file_${id}">${file.name}
        <div id="${this.idPrefix}upload_file_progress_${id}" class="progress" style="width: 50px; display: inline-table;">
          <div id="${this.idPrefix}upload_file_progress_bar_${id}" class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 0%">0%</div>
        </div>
      </li>
      `
    );

    this.upload.create((error, blob) => {
      if (error) {
        // Handle the error
        const className = "text-danger";
        const lineElement = document.getElementById(
          `${this.idPrefix}upload_file_${id}`
        );
        const arr = lineElement.className.split(" ");
        if (arr.indexOf(className) == -1) {
          lineElement.className += " " + className;
        }
      } else {
        // Add an appropriately-named hidden input to the form
        // with a value of blob.signed_id
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = this.fileInputField.name;
        this.fileInputField.form.appendChild(hiddenField);
        window.filesUploading = window.filesUploading.filter(
          (id) => id !== this.upload?.id
        );

        const elementPrarent = this.fileInputField.closest(".content-upload");
        elementPrarent
          .querySelector(".content_show_file")
          .classList.remove("d-none");
        if (blob.content_type.includes("image")) {
          console.log({
            blob: blob.key,
            s3: gon.s3_base_url,
          });
          elementPrarent
            .querySelector(".show-image")
            .setAttribute("src", `${gon.s3_base_url}/${blob.key}`);
          if (elementPrarent.querySelector(".file_url"))
            elementPrarent.querySelector(".file_url").value =
              gon.s3_base_url + "/" + blob.key;
          elementPrarent
            .querySelector(".show-image")
            .classList.remove("d-none");
          if (elementPrarent.querySelector(".show-image"))
            elementPrarent
              .querySelector(".show-image")
              ?.classList.add("d-block");
          if (elementPrarent.querySelector(".show-video"))
            elementPrarent
              .querySelector(".show-video")
              .classList.remove("d-block");
          if (elementPrarent.querySelector(".show-video"))
            elementPrarent.querySelector(".show-video").classList.add("d-none");
          if (elementPrarent.querySelector(".is_image"))
            elementPrarent.querySelector(".is_image").value = true;
        } else {
          var video = elementPrarent.querySelector(".show-video");
          var source = document.createElement("source");
          source.setAttribute("src", `${gon.s3_base_url}/${blob.key}`);
          source.setAttribute("type", "video/mp4");

          video.appendChild(source);
          video.controls = true;
          video.load();
          console.log({
            src: source.getAttribute("src"),
            type: source.getAttribute("type"),
          });
          elementPrarent
            .querySelector(".show-video")
            ?.classList.remove("d-none");
          elementPrarent.querySelector(".show-video")?.classList.add("d-block");
          elementPrarent
            .querySelector(".show-image")
            .classList.remove("d-block");
          elementPrarent.querySelector(".show-image")?.classList.add("d-none");
          elementPrarent.querySelector(".file_url").value =
            gon.s3_base_url + "/" + blob.key;
          elementPrarent.querySelector(".is_image").value = false;
        }
      }
      document
        .getElementById(`${this.idPrefix}upload_file_progress_${id}`)
        .remove();
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    const { id } = this.upload;
    request.upload.addEventListener("progress", (event) => {
      const progress = parseInt((event.loaded * 100) / event.total);
      const progressElement = document.getElementById(
        `${this.idPrefix}upload_file_progress_bar_${id}`
      );
      progressElement.style.width =
        progressElement.textContent = `${progress}%`;
    });
  }
}
