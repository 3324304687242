import { Base } from "./base";
export class Home extends Base {
  index(){
    this.topSlide()
    this.handleMoreCategoryButton()
  }
  search() {
    this.handleFilterButton();
    this.handleSearchButton();
    this.handleSearchMobileButton();
    this.handleFilter();
  }

  topSlide() {
    $(".slider").slick({
      autoplay: true,
      autoplaySpeed: 2000,
      asNavFor: ".thumbnail",
      prevArrow: '<a class="btn mt-3 me-3 btn-icon btn-outline-primary slide-arrow prev-arrow"><i class="fa fa-angle-left"></i></a>',
      nextArrow: '<a class="btn mt-3 btn-icon btn-outline-primary slide-arrow next-arrow"><i class="fa fa-angle-right"></i></a>'
    });
    $(".thumbnail").slick({
      slidesToShow: 6,
      asNavFor: ".slider",
      focusOnSelect: true,
    });
  }

  handleMoreCategoryButton() {
    $('#btn-more').on('click', function() {
      if ($('#more-category').hasClass('d-none')) {
        $('#btn-more').text('閉じる');
        $('#more-category').removeClass('d-none');
        $('#more-category').addClass('d-block');
      } else {
        $('#btn-more').text('もっと見る');
        $('#more-category').removeClass('d-block');
        $('#more-category').addClass('d-none');
      }
    });
  }

  handleFilterButton() {
    $('#btn-more').on('click', function() {
      if ($('#more-filter').hasClass('d-none')) {
        $('#btn-more').text('閉じる');
        $('#more-filter').removeClass('d-none');
        $('#more-filter').addClass('d-block');
      } else {
        $('#btn-more').text('さらに絞り込む');
        $('#more-filter').removeClass('d-block');
        $('#more-filter').addClass('d-none');
      }
    });
  }

  handleSearchButton() {
    $('#btn-search').on('click', function(e) {
      $('#q_title_or_overview_cont').val($('#s-keyword').val());
      $('#project_search').submit();
    });
  }

  handleSearchMobileButton() {
    $('#btn-mobile-search').on('click', function(e) {
      $('#q_title_or_overview_cont').val($('#s-mobile-keyword').val());
      $('#project_search').submit();
    });
  }

  handleFilter() {
    document.querySelectorAll(".target-amount").forEach((el) => {
      $(el).on('change', function() {
        $('#project_search').submit();
      });
    });

    document.querySelectorAll(".reward-amount").forEach((el) => {
      $(el).on('change', function() {
        $('#project_search').submit();
      });
    });

    document.querySelectorAll(".rate").forEach((el) => {
      $(el).on('change', function() {
        $('#project_search').submit();
      });
    });


    $('#q_categories_id_eq').on('change', function() {
      $('#project_search').submit();
    });

    $('#project-sort').on('change', function() {
      $('#project_search').submit();
    });
  }
}
