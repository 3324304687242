// Usage: https://github.com/caroso1222/notyf
import { Notyf } from "notyf";

const initialize = () => {
  window.notyf = new Notyf({
    duration: 5000,
    position: {
      x: "center",
      y: "top",
    },
    types: [
      {
        type: "default",
        backgroundColor: "#3B7DDD",
        icon: {
          className: "notyf__icon--success",
          tagName: "i",
        },
      },
      {
        type: "success",
        backgroundColor: "#F6FFED",
        icon: {
          className: "notyf__icon--success",
          tagName: "i",
        },
      },
      {
        type: "warning",
        backgroundColor: "#ffc107",
        icon: {
          className: "notyf__icon--error",
          tagName: "i",
        },
      },
      {
        type: "danger",
        backgroundColor: "#dc3545",
        icon: {
          className: "notyf__icon--error",
          tagName: "i",
        },
      },
    ],
  });
};

// Wait until page is loaded
document.addEventListener("turbolinks:load", () => initialize());
