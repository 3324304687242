// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "../lib/app.js";
const images = require.context("../images", true);

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Common

// Controllers
import * as controllers from "../javascripts/controllers";
import "../javascripts/lib/overlay.js";
import "../javascripts/lib/datepicker.js";
import "../javascripts/lib/select2.js";
import "../javascripts/lib/redirect_page.js";
import "../javascripts/lib/show_error_message";
import "../javascripts/lib/init_i18n";
import "../javascripts/lib/notyf.js";
import "../javascripts/lib/slick.min.js";

document.addEventListener("turbolinks:load", function () {
  //ページを読み込んだ時にwindow.Appにコントローラー毎のオブジェクトを代入する
  const body = document.querySelector("body");
  if (body) {
    // 検索
    $('#btn-search').on('click', function(e) {
      window.location.replace("/search?q[title_or_overview_cont]=" + $('#s-keyword').val());
    });
    $('#btn-mobile-search').on('click', function(e) {
      window.location.replace("/search?q[title_or_overview_cont]=" + $('#s-mobile-keyword').val());
    });

    const data = body.dataset;
    if (data) {
      const controllerName = data.controller;
      const actionName = data.action;
      if (
        controllerName &&
        actionName &&
        typeof controllers[controllerName] === "function"
      ) {
        window.App = new controllers[controllerName](actionName);
      }
    }
  }
});
