import { Base } from "../base";
import Rails from "@rails/ujs";

export class Users__Supporters extends Base {
  payment() {
    console.log("Users__Supporters#payment");
    this.initRadioButtonIsUsing();
    // Payment
    const stripe = Stripe(gon.stripe_publishable);

    // Disable the button until we have Stripe set up on the page
    document.querySelector("button").disabled = true;
    var elements = stripe.elements();
    var style = {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };

    var card = elements.create("card", {
      style: style,
      hidePostalCode: true,
      hideIcon: false,
    });
    // Stripe injects an iframe into the DOM
    card.mount("#card-element");

    card.on("change", function (event) {
      // Disable the Pay button if there are no card details in the Element
      document.querySelector("button").disabled = event.empty;
      document.querySelector("#card-error").textContent = event.error
        ? event.error.message
        : "";
    });

    var form = document.getElementById("payment-form");
    form.addEventListener("submit", async function (event) {
      event.preventDefault();
      loading(true);
      try {
        const tokenRes = await stripe.createToken(card, {
          name: document.getElementById("card_name").value,
        });
        const token = tokenRes.token;
        const response = await fetch("/users/supporters/create_customer", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": Rails.csrfToken(),
          },
          body: JSON.stringify({ token: token.id, is_using: true }),
        });
        const data = await response.json();
        if (!data.error) {
          window.redirectPage.go(
            `/users/supporters/${
              document.getElementById("supporter_id").value
            }/confirm`
          );
        }
      } catch (e) {
        var errorElement = document.getElementById("card-error");
        errorElement.textContent = result.error.message;
        console.log(e);
      } finally {
        loading(false);
      }
    });

    // Show a spinner on payment submission
    var loading = function (isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("button").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
      } else {
        document.querySelector("button").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
      }
    };
  }

  show() {
    this.initEventSelectAddress();
    this.initInputPostcode();
    this.initButtonRemoveAddress();
  }

  confirm() {
    console.log("Users__Supporters#confirm");
    this.initButtonSubmitCharge();
  }
  /**
   * It takes the values of the inputs and selects and puts them in the hidden inputs
   */
  initEventSelectAddress() {
    const button = document.querySelector("#btn_select_address");
    if (button) {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        if (this.validateFormAddress()) {
          const params = {
            name: document.querySelector(".input_name").value,
            post_code: document.querySelector(".input_post_code").value,
            pref: document.querySelector(".select-pref").value,
            city: document.querySelector(".input_city").value,
            state: document.querySelector(".input_state").value,
            other: document.querySelector(".input_other").value,
            phone: document.querySelector(".input_phone").value,
            country: document.querySelector(".select-event").value,
            id: document.querySelector(
              "#reward_supporters_attributes_0_address_id"
            ).value,
          };
          const res = await fetch("/users/rewards/create_address", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": Rails.csrfToken(),
            },
            body: JSON.stringify({
              address: params,
            }),
          });
          const data = await res.json();
          const address = data.address;
          if (address) {
            document.querySelector(
              "#reward_supporters_attributes_0_address_id"
            ).value = address.id;
            document.querySelector(".btn_close_modal").click();
            document.querySelector("#btn_submit").disabled = false;
            const divNoneAddress = document.querySelector(".none-address");
            if (divNoneAddress) {
              divNoneAddress.classList.add("d-none");
            }
            const divAddress = document.querySelector(".has-address");
            if (divAddress) {
              divAddress.classList.remove("d-none");
              document.querySelector(".label_name").textContent = address.name;
              const postCode = address.post_code;
              document.querySelector(".label_post_code").textContent =
                "〒" + postCode.slice(0, 3) + "-" + postCode.slice(3, 7);
              const fullAddress =
                address.pref + address.city + address.state + address.other;
              document.querySelector(".label_full_address").textContent =
                fullAddress;
              document.querySelector(".label_phone").textContent =
                address.phone;
            }
          }
        } else {
          console.log("invalid");
        }
      });
    }
  }
  validateFormAddress() {
    // clear error message
    const errorMessages = document.querySelectorAll(".div-error");
    errorMessages.forEach((errorMessage) => {
      errorMessage.classList.add("d-none");
      errorMessage.classList.remove("invalid-feedback");
    });
    const inputs = document.querySelectorAll(".input-event");
    inputs.forEach((input) => input.classList.remove("input-error"));
    let isValid = true;
    inputs.forEach((input) => {
      if (!input.value) {
        isValid = false;
        input.classList.add("input-error");
        const parent = input.closest(".form-group");
        if (parent) {
          const divError = parent.querySelector(".div-error");
          if (divError) {
            divError.classList.remove("d-none");
            divError.classList.add("invalid-feedback");
          }
        }
      }
    });
    const selects = document.querySelectorAll(".select-event");
    selects.forEach((select) => select.classList.remove("input-error"));
    selects.forEach((select) => {
      if (!select.value) {
        isValid = false;
        select.classList.add("input-error");
        const parent = select.closest(".form-group");
        if (parent) {
          const divError = parent.querySelector(".div-error");
          if (divError) {
            divError.classList.remove("d-none");
            divError.classList.add("invalid-feedback");
          }
        }
      }
    });
    return isValid;
  }
  /**
   * It adds an event listener to the input element with the class name "input_post_code" and when the
   * user types in a 7 digit number, it fetches the data from the API and fills in the other input
   * elements with the data
   */
  initInputPostcode() {
    const input = document.querySelector(".input_post_code");
    if (input) {
      input.addEventListener("input", async (event) => {
        const value = event.target.value;
        if (value && value.length == 7) {
          const res = await fetch(
            "https://madefor.github.io/postal-code-api/api/v1/" +
              value.slice(0, 3) +
              "/" +
              value.slice(3, 7) +
              ".json"
          );
          const data = await res.json();
          if (data && data.data && data.data.length > 0) {
            const postCodeJa = data.data[0].ja;
            const prefecture = postCodeJa.prefecture;
            // document.querySelector(".select-pref").value = "神奈川県";
            $(".select-pref").val(prefecture).trigger("change", true);
            const city = postCodeJa.address1;
            document.querySelector(".input_city").value = city;
            const state = postCodeJa.address2;
            document.querySelector(".input_state").value = state;
          }
        }
      });
    }
  }

  initButtonSubmitCharge() {
    const button = document.querySelector("#btn_submit_charge");
    if (button) {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        const supporterId = document.querySelector("#supporter_id").value;
        const res = await fetch("/users/supporters/charge", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": Rails.csrfToken(),
          },
          body: JSON.stringify({
            id: supporterId,
          }),
        });
        const data = await res.json();
        if (data && !data.error) {
          window.redirectPage.go(
            `/users/supporters/${supporterId}/payment_completed`
          );
        } else {
          alert(data.error);
        }
      });
    }
  }

  initRadioButtonIsUsing() {
    const radios = document.querySelectorAll(".is_using");
    if (radios) {
      radios.forEach((radio) => {
        radio.addEventListener("click", (event) => {
          const value = event.target.value;
          const divCardInfo = document.querySelector(".content_new_card");
          const buttonNext = document.querySelector(".content_button_next");
          if (value == "no") {
            divCardInfo.classList.remove("d-none");
            buttonNext.classList.add("d-none");
          } else {
            divCardInfo.classList.add("d-none");
            buttonNext.classList.remove("d-none");
          }
        });
      });
    }
  }

  initButtonRemoveAddress() {
    const link = document.querySelector(".link_remove_address");
    if (link) {
      link.addEventListener("click", async (event) => {
        if (window.confirm("Are you sure you want to remove")) {
          const supporterId = document.querySelector(
            "#reward_supporters_attributes_0_id"
          ).value;
          const res = await fetch("/users/supporters/remove_address", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": Rails.csrfToken(),
            },
            body: JSON.stringify({
              id: supporterId,
            }),
          });
          const data = await res.json();
          if (data && !data.error) {
            console.log("removed");
            window.redirectPage.go(window.location.href);
          } else {
            console.log(data.error);
          }
        }
      });
    }
  }
}
