/**
 * スネークケースへ変換 sample_string
 * @param string
 * @return string
 */
export function snakeCase(str) {
  var camel = camelCase(str);
  return camel.replace(/[A-Z]/g, function (s) {
    return "_" + s.charAt(0).toLowerCase();
  });
}

/**
 * キャメルケースへ変換 sampleString
 * @param string
 * @return string
 */
export function camelCase(str) {
  str = str.charAt(0).toLowerCase() + str.slice(1);
  return str.replace(/[-_](.)/g, function (match, group1) {
    return group1.toUpperCase();
  });
}

/**
 * パスカルケースへ変換 SampleString
 * @param string
 * @return string
 */
export function pascalCase(str) {
  var camel = camelCase(str);
  return camel.charAt(0).toUpperCase() + camel.slice(1);
}
