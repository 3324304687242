document.addEventListener("turbolinks:load", function () {
  window.redirectPage = new RedirectPage();
});

class RedirectPage {
  /**
   * ページに移遷した後notifyを表示する
   * @param {String} url
   * @param {String, Object, Function} options
   */
  go(url, options = "") {
    new Promise(function (resolve) {
      resolve(Turbolinks.visit(url));
    }).then(function () {
      document.addEventListener(
        "turbolinks:load",
        function () {
          switch (typeof options) {
            case "function":
              options();
              break;
            case "object":
              Object.entries(options).forEach(([key, value]) => {
                let type = "";
                switch (key.toLowerCase()) {
                  case "error":
                    type = "error";
                    break;
                  case "success":
                    type = "success";
                    break;
                  default:
                    type = "error";
                    break;
                }
                window.notyf.open({
                  type,
                  message: value,
                });
              });
              break;
            case "string":
              if (!options) return;
              window.notyf.success({
                message: options,
              });
              break;
            default:
              window.notyf.success({
                message: options,
              });
              break;
          }
        },
        { once: true }
      );
    });
  }
}
