document.addEventListener("turbolinks:load", function () {
  (function () {
    "use strict";

    var slideMenu = $(".side-menu");

    // Toggle Sidebar
    $('[data-bs-toggle="sidebar"]').click(function (event) {
      event.preventDefault();
      $(".app").toggleClass("sidenav-toggled");
    });

    $(".app-sidebar").hover(
      function () {
        if ($(".app").hasClass("sidenav-toggled")) {
          $(".app").addClass("sidenav-toggled1");
        }
      },
      function () {
        if ($(".app").hasClass("sidenav-toggled")) {
          $(".app").removeClass("sidenav-toggled1");
        }
      }
    );

    // Set initial active toggle
    $(".sub-slide-show.is-expanded").parent().toggleClass("is-expanded");
    $(".child-sub-menu.open")
      .parent()
      .parent()
      .parent()
      .parent()
      .parent()
      .toggleClass("is-expanded");

    // Activate sidebar slide toggle
    $(".slide-show").on("click", function (e) {
      var $this = $(this);
      var checkElement = $this.next();
      var animationSpeed = 300,
        slideMenuSelector = ".slide-menu";
      if (checkElement.is(slideMenuSelector) && checkElement.is(":visible")) {
        checkElement.slideUp(animationSpeed, function () {
          checkElement.removeClass("open");
        });
        checkElement.parent("li").removeClass("is-expanded");
      } else if (
        checkElement.is(slideMenuSelector) &&
        !checkElement.is(":visible")
      ) {
        var parent = $this.parents("ul").first();
        var ul = parent.find("ul:visible").slideUp(animationSpeed);
        ul.removeClass("open");
        var parent_li = $this.parent("li");
        checkElement.slideDown(animationSpeed, function () {
          checkElement.addClass("open");
          parent.find("li.is-expanded").removeClass("is-expanded");
          parent_li.addClass("is-expanded");
        });
      }
      if (checkElement.is(slideMenuSelector)) {
        e.preventDefault();
      }
    });

    // Activate sidebar slide toggle
    $(".sub-slide-show").on("click", function (e) {
      var $this = $(this);
      var checkElement = $this.next();
      var animationSpeed = 300,
        slideMenuSelector = ".child-sub-menu";
      if (checkElement.is(slideMenuSelector) && checkElement.is(":visible")) {
        checkElement.slideUp(animationSpeed, function () {
          checkElement.removeClass("open");
        });
        checkElement.parent("li").removeClass("is-expanded");
      } else if (
        checkElement.is(slideMenuSelector) &&
        !checkElement.is(":visible")
      ) {
        var parent = $this.parents("ul").first();
        var ul = parent.find("ul:visible").slideUp(animationSpeed);
        ul.removeClass("open");
        var parent_li = $this.parent("li");
        checkElement.slideDown(animationSpeed, function () {
          checkElement.addClass("open");
          parent.find("li.is-expanded").removeClass("is-expanded");
          parent_li.addClass("is-expanded");
        });
      }
      if (checkElement.is(slideMenuSelector)) {
        e.preventDefault();
      }
    });

    // Activate sidebar slide toggle
    $(".sub-slide2-show").on("click", function (e) {
      var $this = $(this);
      var checkElement = $this.next();
      var animationSpeed = 300,
        slideMenuSelector = ".child-sub-menu2";
      if (checkElement.is(slideMenuSelector) && checkElement.is(":visible")) {
        checkElement.slideUp(animationSpeed, function () {
          checkElement.removeClass("open");
        });
        checkElement.parent("li").removeClass("is-expanded");
      } else if (
        checkElement.is(slideMenuSelector) &&
        !checkElement.is(":visible")
      ) {
        var parent = $this.parents("ul").first();
        var ul = parent.find("ul:visible").slideUp(animationSpeed);
        ul.removeClass("open");
        var parent_li = $this.parent("li");
        checkElement.slideDown(animationSpeed, function () {
          checkElement.addClass("open");
          parent.find("li.is-expanded").removeClass("is-expanded");
          parent_li.addClass("is-expanded");
        });
      }
      if (checkElement.is(slideMenuSelector)) {
        e.preventDefault();
      }
    });

    // ______________Active Class
    $(document).ready(function () {
      $(".app-sidebar li a").each(function () {
        var pageUrl = window.location.href.split(/[?#]/)[0];
        if (this.href == pageUrl) {
          $(this).addClass("active");
          $(this).parent().addClass("is-expanded");
          $(this).parent().parent().prev().addClass("active");
          $(this).parent().parent().addClass("open");
          $(this).parent().parent().prev().addClass("is-expanded");
          $(this).parent().parent().parent().addClass("is-expanded");
          $(this).parent().parent().parent().parent().addClass("open");
          $(this).parent().parent().parent().parent().prev().addClass("active");
          $(this)
            .parent()
            .parent()
            .parent()
            .parent()
            .parent()
            .addClass("is-expanded");
        }
      });
    });
  })();
});
