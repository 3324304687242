import { Base } from "../base";

export class Users__Comments extends Base {
  edit() {
    this.initButtonGoBack();
  }

  /**
   * It adds an event listener to the button with the id of btn_go_back. When the button is clicked, the
   * event listener will prevent the default action of the button, and then redirect the user to the
   * project page
   */
  initButtonGoBack() {
    const button = document.querySelector("#btn_go_back");
    button.addEventListener("click", (event) => {
      event.preventDefault();
      window.redirectPage.go(
        "/users/projects/" + button.getAttribute("data-id"),
        function () {
          window.App.activeTabPage("tab_comment");
        }
      );
    });
  }
}
