(function ($) {
  "use strict";

  // ______________ Global Loader
  document.addEventListener("turbolinks:load", function () {
    $("#global-loader").fadeOut("slow");
  });

  // ______________ Cover-image
  $(".cover-image").each(function () {
    var attr = $(this).attr("data-bs-image-src");
    if (typeof attr !== typeof undefined && attr !== false) {
      $(this).css("background", "url(" + attr + ") center center");
    }
  });

  //______________ input control
  $(".location-gps").click(function (event) {
    event.preventDefault();
    $(".location-loader").toggleClass("active");
  });

  // ______________ File Upload
  $(document).on("change", ":file", function () {
    var input = $(this),
      numFiles = input.get(0).files ? input.get(0).files.length : 1,
      label = input.val().replace(/\\/g, "/").replace(/.*\//, "");
    input.trigger("fileselect", [numFiles, label]);
  });

  // We can watch for our custom `fileselect` event like this
  $(document).ready(function () {
    $(":file").on("fileselect", function (event, numFiles, label) {
      var input = $(this).parents(".input-group").find(":text"),
        log = numFiles > 1 ? numFiles + " files selected" : label;
      if (input.length) {
        input.val(log);
      } else {
        // if (log) alert(log);
      }
    });
  });

  // ______________ Color-skin
  $(document).ready(function () {
    $("a[data-bs-theme]").click(function () {
      $("head link#theme").attr("href", $(this).data("theme"));
      $(this).toggleClass("active").siblings().removeClass("active");
    });
    $("a[data-bs-effect]").click(function () {
      $("head link#effect").attr("href", $(this).data("effect"));
      $(this).toggleClass("active").siblings().removeClass("active");
    });
  });

  // ______________Rating Stars
  var ratingOptions = {
    selectors: {
      starsSelector: ".rating-stars",
      starSelector: ".rating-star",
      starActiveClass: "is--active",
      starHoverClass: "is--hover",
      starNoHoverClass: "is--no-hover",
      targetFormElementSelector: ".rating-value",
    },
  };

  // ______________Active Class
  $(document).ready(function () {
    $(".horizontalMenu-list li a").each(function () {
      var pageUrl = window.location.href.split(/[?#]/)[0];
      if (this.href == pageUrl) {
        $(this).addClass("active");
        $(this).parent().addClass("active"); // add active to li of the current link
        $(this).parent().parent().prev().addClass("active"); // add active class to an anchor
        $(this).parent().parent().prev().click(); // click the item to make it drop
      }
    });
  });

  // ______________ Back to Top
  document.addEventListener("turbolinks:load", function () {
    $(window).on("scroll", function (e) {
      if ($(this).scrollTop() > 0) {
        $("#back-to-top").fadeIn("slow");
      } else {
        $("#back-to-top").fadeOut("slow");
      }
    });
    $("#back-to-top").on("click", function (e) {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        600
      );
      return false;
    });
  });

  // ______________Chart-circle
  if ($(".chart-circle").length) {
    $(".chart-circle").each(function () {
      let $this = $(this);
      $this.circleProgress({
        fill: {
          color: $this.attr("data-color"),
        },
        size: $this.height(),
        startAngle: (-Math.PI / 4) * 2,
        emptyFill: "#f9faff",
        lineCap: "",
      });
    });
  }
  const DIV_CARD = "div.card";

  // ___________TOOLTIP
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // __________POPOVER
  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  );
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });
  // By default, Bootstrap doesn't auto close popover after appearing in the page
  $(document).on("click", function (e) {
    $('[data-bs-toggle="popover"],[data-original-title]').each(function () {
      if (
        !$(this).is(e.target) &&
        $(this).has(e.target).length === 0 &&
        $(".popover").has(e.target).length === 0
      ) {
        (
          ($(this).popover("hide").data("bs.popover") || {}).inState || {}
        ).click = false; // fix for BS 3.3.6
      }
    });
  });

  // ______________Card Remove
  $('[data-bs-toggle="card-remove"]').on("click", function (e) {
    let $card = $(this).closest(DIV_CARD);
    $card.remove();
    e.preventDefault();
    return false;
  });

  // ______________Card Collapse
  $('[data-bs-toggle="card-collapse"]').on("click", function (e) {
    let $card = $(this).closest(DIV_CARD);
    $card.toggleClass("card-collapsed");
    e.preventDefault();
    return false;
  });

  // ______________Card Full Screen
  $('[data-bs-toggle="card-fullscreen"]').on("click", function (e) {
    let $card = $(this).closest(DIV_CARD);
    $card.toggleClass("card-fullscreen").removeClass("card-collapsed");
    e.preventDefault();
    return false;
  });

  //Increment & Decrement
  var quantitiy = 0;
  $(".quantity-right-plus").on("click", function (e) {
    e.preventDefault();
    var quantity = parseInt($(".quantity").val());
    $(".quantity").val(quantity + 1);
  });
  $(".quantity-left-minus").on("click", function (e) {
    e.preventDefault();
    var quantity = parseInt($(".quantity").val());
    if (quantity > 0) {
      $(".quantity").val(quantity - 1);
    }
  });
  // ______________Quantity-right-plus
  var quantitiy = 0;
  $(".quantity-right-plus").on("click", function (e) {
    e.preventDefault();
    var quantity = parseInt($("#quantity").val());
    $("#quantity").val(quantity + 1);
  });
  $(".quantity-left-minus").on("click", function (e) {
    e.preventDefault();
    var quantity = parseInt($("#quantity").val());
    if (quantity > 0) {
      $("#quantity").val(quantity - 1);
    }
  });

  $(".navresponsive-toggler").click(function (event) {
    event.preventDefault();
    $(".navbar-collapse").toggleClass("navbar-collapse-open");
  });

  $(".navbar-close-btn").click(function (event) {
    event.preventDefault();
    $(".navbar-collapse").removeClass("navbar-collapse-open");
  });
})(jQuery);

// ______________ Modal
$(document).ready(function () {
  if (document.querySelector("#myModal")) $("#myModal").modal("show");
});

(function checkRtl() {
  let bodyRtl = $("body").hasClass("rtl");
  if (bodyRtl) {
    $("body").addClass("rtl");
    $("body").removeClass("ltr");
    $("html[lang=en]").attr("dir", "rtl");
    $(".horizontalMenu-list span").attr("dir", "rtl");
    $(".top-bar li a .fe").attr("dir", "rtl");
    localStorage.setItem("rtl", "True");
    $("head link#style").attr("href", $(this));
  } else {
    $("body").addClass("ltr");
    $("body").removeClass("rtl");
    $("html[lang=en]").attr("dir", "ltr");
    $(".horizontalMenu-list span").attr("dir", "ltr");
    $(".top-bar li a .fe").attr("dir", "ltr");
    localStorage.setItem("rtl", "false");
    $("head link#style").attr("href", $(this));
  }
})();
