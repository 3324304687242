// JS
import "./js/modules/jquery-3.5.1.min.js";
import "./js/modules/plugins/bootstrap/js/bootstrap.min.js";
import "./js/modules/plugins/bootstrap/js/popper.min.js";

import "./js/modules/jquery.sparkline.min.js";
import "./js/modules/circle-progress.min.js";
// import "./js/modules/plugins/horizontal-menu/horizontal.js";
import "./js/modules/plugins/counters/counterup.min.js";
import "./js/modules/plugins/counters/numeric-counter.js";
import "./js/modules/plugins/jquery-bar-rating/js/rating.js";
import "./js/modules/plugins/jquery-bar-rating/jquery.barrating.js";
import "./js/modules/jquery.touchSwipe.min.js";
import "./js/modules/select2.js";
import "./js/modules/plugins/select2/select2.full.min.js";
import "./js/modules/waves.js";
import "./js/modules/plugins/pscrollbar/pscrollbar.js";
// import "./js/modules/plugins/cookie/cookie.js";
// import "./js/modules/plugins/cookie/jquery.ihavecookies.js";
import "./js/modules/plugins/jquery.flexdatalist/jquery.flexdatalist.js";
import "./js/modules/plugins/jquery.flexdatalist/data-list.js";
import "./js/modules/plugins/jquery-uislider/jquery-ui.js";
import "./js/modules/jquery.showmore.js";
import "./js/modules/showmore.js";
import "./js/modules/swipe.js";
import "./js/modules/custom.js";
import "./js/modules/plugins/fileuploads/js/dropify.js";
import "./js/modules/formelements.js";
import "./js/modules/plugins/input-mask/jquery.maskedinput.js";
import "./js/modules/plugins/jquery-inputmask/jquery.inputmask.bundle.min.js";
import "./js/modules/plugins/time-picker/toggles.min.js";
import "./js/modules/plugins/time-picker/jquery.timepicker.js";
import "./js/modules/plugins/date-picker/spectrum.js";
import "./js/modules/sticky";
import "./js/modules/sticky-mobile";
import "./js/modules/sidemenu";

import "./js/modules/summernote-lite.min.js";
import "./js/modules/plugins/wysiwyag/jquery.richtext.js";
import "./js/modules/notify.js";
