import { Base } from "../base";
import { Uploader } from "../../lib/file_uploader";

export class Users__Mypage extends Base {
  profile_edit() {
    window.filesUploading = [];
    this.initEventUpload();
  }
  email_change() {
  }
  password_change() {
  }
  address() {
    $("#open-form").on("click", function (e) {
      $("#shipping-information").removeClass("d-none").css("display", "block");
      $("#open-form").removeClass("d-flex").css("display", "none");
    });
    this.initInputPostcode();
  }

  creditcard() {
    this.initRadioButtonIsUsing();
    // Payment
    const stripe = Stripe(gon.stripe_publishable);

    // Disable the button until we have Stripe set up on the page
    document.querySelector("button").disabled = true;
    var elements = stripe.elements();
    var style = {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };

    var card = elements.create("card", {
      style: style,
      hidePostalCode: true,
      hideIcon: false,
    });
    // Stripe injects an iframe into the DOM
    card.mount("#card-element");

    card.on("change", function (event) {
      // Disable the Pay button if there are no card details in the Element
      document.querySelector("button").disabled = event.empty;
      document.querySelector("#card-error").textContent = event.error
        ? event.error.message
        : "";
    });

    var form = document.getElementById("payment-form");
    form.addEventListener("submit", async function (event) {
      event.preventDefault();
      loading(true);
      try {
        const tokenRes = await stripe.createToken(card, {
          name: document.getElementById("card_name").value,
        });
        const token = tokenRes.token;
        const response = await fetch("/users/supporters/create_customer", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document.getElementsByName("csrf-token")[0].getAttribute("content"),
          },
          body: JSON.stringify({ token: token.id, is_using: true }),
        });
        const data = await response.json();

        if (!data.error) {
          window.notyf.success({
            message: "変更しました",
          });
          window.location.reload();
        } else {
           window.notyf.danger({
            message: "変更失敗しました。",
          });
        }
      } catch (e) {
        var errorElement = document.getElementById("card-error");
        errorElement.textContent = e.message;
      } finally {
        loading(false);
      }
    });

    // Show a spinner on payment submission
    var loading = function (isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("button").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
      } else {
        document.querySelector("button").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
      }
    };
  }
  /**
   * It adds an event listener to the input element with the class name "input_post_code" and when the
   * user types in a 7 digit number, it fetches the data from the API and fills in the other input
   * elements with the data
   */
  initInputPostcode() {
    const input = document.querySelector(".input_post_code");
    if (input) {
      input.addEventListener("input", async (event) => {
        const value = event.target.value;
        if (value && value.length == 7) {
          const res = await fetch(
            "https://madefor.github.io/postal-code-api/api/v1/" +
              value.slice(0, 3) +
              "/" +
              value.slice(3, 7) +
              ".json"
          );
          const data = await res.json();
          if (data && data.data && data.data.length > 0) {
            const postCodeJa = data.data[0].ja;
            const prefecture = postCodeJa.prefecture;
            // document.querySelector(".select-pref").value = "神奈川県";
            $(".select-pref").val(prefecture).trigger("change", true);
            const city = postCodeJa.address1;
            document.querySelector(".input_city").value = city;
            const state = postCodeJa.address2;
            document.querySelector(".input_state").value = state;
          }
        }
      });
    }
  }

  initEventUpload() {
    const inputs = document.querySelectorAll(".multi-direct-upload");
    inputs.forEach(function (input) {
      input.addEventListener("change", async (event) => {
        $('.user_file_upload-files-list').empty();
        input.disabled = true;
        await Array.from(input.files).forEach((file) => uploadFile(file));
        input.value = null;
        input.disabled = false;
      });
      const uploadFile = (file) => {
        const upload = new Uploader(file, input);
        window.filesUploading.push(upload?.upload?.id);
        upload.start();
      };
    });
  }

  initRadioButtonIsUsing() {
    const radios = document.querySelectorAll(".is_using");
    if (radios) {
      radios.forEach((radio) => {
        radio.addEventListener("click", (event) => {
          const value = event.target.value;
          const divCardInfo = document.querySelector(".content_new_card");
          if (value == "no") {
            divCardInfo.classList.remove("d-none");
          } else {
            divCardInfo.classList.add("d-none");
          }
        });
      });
    }
  }
}
