document.addEventListener("turbolinks:load", function () {
  window.Overlay = new Overlay();
});

class Overlay {
  constructor() {
    this.divLoading = document.querySelector("#global-loader");
  }
  /**
   * ロード表示
   * @param {String} messages
   */
  show() {
    this.divLoading.style.display = "block";
  }
  /**
   * ロード非表示
   */
  hide() {
    this.divLoading.style.display = "none";
  }
}
