import { Base } from "./base";

export class Comments extends Base {
  edit() {
    this.initButtonGoBack();
  }

  initButtonGoBack() {
    const button = document.querySelector("#btn_go_back");
    button.addEventListener("click", (event) => {
      event.preventDefault();
      window.redirectPage.go(
        "/projects/" + button.getAttribute("data-id"),
        function () {
          window.App.activeTabPage("tab_comment");
        }
      );
    });
  }
}
