import { Base } from "../base";

import { Uploader } from "../../lib/file_uploader";
const MAX_ITEM_UPLOAD = 8;

export class Users__Projects extends Base {
  // 共有ロジック
  // 共有ロジックない場合は、定義不要
  draft_projects() {
    console.log("draft_projects");
    this.initButtonReadMorePrjDraft();
    this.initButtonReadMorePrjReviewing();
    this.initButtonReadMorePrjReJected();
    this.initButtonReadMorePrjWaitingPublic();
  }

  published_projects() {
    console.log("published_projects");
    this.initButtonReadMorePrjPublishedInprogress();
    this.initButtonReadMorePrjPublishedDone();
  }

  completed_projects() {
    console.log("completed_projects");
    this.initButtonReadMorePrjCompletedInprogress();
    this.initButtonReadMorePrjCompletedDone();
  }

  common() {
    window.filesUploading = [];
  }

  new() {
    console.log("Create new project");
    this.initButtonAddField();
    this.initButtonRemoveField();
    this.initRadioButtonTargetAmountChange();
    this.initEventUpload();
    this.initYoutube();
  }

  show() {
    this.slideInit();
    this.initButtonAddNewReport();
    this.initButtonAddNewComment();
    this.initEventDeleteReport();
    this.initEventDeleteComment();
    this.showMessageList();
    this.showMessageDetail();
    this.showSupporters();
  }

  info() {
    console.log("info");
    this.initButtonAddField();
    this.initButtonRemoveField();
    this.initRadioButtonTargetAmountChange();
    window.filesUploading = [];
    this.initEventUpload();
    this.initYoutube();
  }

  reward() {
    this.initButtonAddField();
    this.initButtonRemoveField();
    window.filesUploading = [];
    this.initEventUpload();
    this.countNumberQuestions();
    this.initEventRadioQuestions();
    this.initEventInputChoices();
    this.initEventRadioSelectOption();
    this.initAddQuestion();
    this.initRemoveQuestion();
  }

  /**
   * It's a function that adds an event listener to each radio button in the form. When the radio button
   * is clicked, it checks the value of the radio button and if the value is 1, it shows the div that
   * contains the questions. If the value is 0, it hides the div that contains the questions
   */
  initEventRadioQuestions() {
    const radios = document.querySelectorAll(".radio_has_choice");
    const radioCondition = document.querySelectorAll(
      ".radio_has_choice:checked"
    );
    radioCondition.forEach((radio) => {
      const value = radio.value;
      const divItemQuestions = radio
        .closest(".form_question")
        .querySelectorAll(".item_question");
      if (value == "true") {
        divItemQuestions.forEach((item) => item.classList.remove("d-none"));
      } else {
        divItemQuestions.forEach((item) => item.classList.add("d-none"));
      }
    });
    radios.forEach((radio) => {
      radio.addEventListener("change", (e) => {
        const value = e.currentTarget.value;
        const divItemQuestions = e.currentTarget
          .closest(".form_question")
          .querySelectorAll(".item_question");
        // const divItemQuestions = document.querySelectorAll(".item_question");
        if (value == "true") {
          // divAnswer.classList.remove("d-none");
          divItemQuestions.forEach((item) => item.classList.remove("d-none"));
        } else {
          divItemQuestions.forEach((item) => item.classList.add("d-none"));
        }
      });
    });
  }

  initEventRadioSelectOption() {
    const radios = document.querySelectorAll(".radio_select_option");
    const radioCondition = document.querySelectorAll(
      ".radio_select_option:checked"
    );
    radioCondition.forEach((radio) => {
      const value = radio.value;
      const divItemQuestions = radio
        .closest(".form_select_option")
        .querySelectorAll(".content_choices");
      // const divItemQuestions = document.querySelectorAll(".item_question");
      if (value == "true") {
        // divAnswer.classList.remove("d-none");
        divItemQuestions.forEach((item) => item.classList.remove("d-none"));
      } else {
        divItemQuestions.forEach((item) => item.classList.add("d-none"));
      }
    });
    radios.forEach((radio) => {
      radio.addEventListener("change", (e) => {
        const value = e.currentTarget.value;
        const divItemQuestions = e.currentTarget
          .closest(".form_select_option")
          .querySelectorAll(".content_choices");
        // const divItemQuestions = document.querySelectorAll(".item_question");
        if (value == "true") {
          // divAnswer.classList.remove("d-none");
          divItemQuestions.forEach((item) => item.classList.remove("d-none"));
        } else {
          divItemQuestions.forEach((item) => item.classList.add("d-none"));
        }
      });
    });
  }

  initEventInputChoices() {
    const inputs = document.querySelectorAll(".input_choices");
    inputs.forEach((input) => {
      input.addEventListener("input", (e) => {
        const inputHidden = e.currentTarget
          .closest(".content_choices")
          .querySelector(".choices_hidden");
        const inputChoices = e.currentTarget
          .closest(".content_choices")
          .querySelectorAll(".input_choice");
        let arrayChoices = [];
        inputChoices.forEach((e) => {
          if (e.value) arrayChoices.push(e.value);
        });
        inputHidden.value = arrayChoices.join(",");
      });
    });
  }

  /**
   * It counts the number of questions in the quiz and displays the number of each question
   */
  countNumberQuestions() {
    const formQuestions = document.querySelectorAll(".form_question") || [];
    formQuestions.forEach((formQuestion) => {
      const numberQuestions =
        formQuestion.querySelectorAll(".item_question") || [];
      let numberDisplay = 1;
      numberQuestions.forEach((divQuestion, index) => {
        if (divQuestion.getAttribute("is_remove") == 0) {
          if (divQuestion.querySelector(".number_question")) {
            divQuestion.querySelector(".number_question").textContent =
              numberDisplay;
            numberDisplay++;
          }
        }
      });
    });
  }

  initEventUpload() {
    const inputs = document.querySelectorAll(".multi-direct-upload");
    inputs.forEach(function (input) {
      input.addEventListener("change", (event) => {
        Array.from(input.files).forEach((file) => uploadFile(file));
        input.value = null;
      });
      const uploadFile = (file) => {
        const upload = new Uploader(file, input);
        window.filesUploading.push(upload?.upload?.id);
        upload.start();
      };
    });
  }

  /**
   * It replaces the `new_object.object_id` with a unique timestamp, and adds the new markup to the form
   */
  initButtonAddField() {
    $(".add_fields").off("click");
    $(".add_fields").on("click", function (e) {
      e.preventDefault();
      const link = e.currentTarget;
      const buildId = link.getAttribute("data-id-build");
      let customHtml = "";
      // Save a unique timestamp to ensure the key of the associated array is unique.
      let time = new Date().getTime();
      if (buildId) {
        // $(".select2-custom").select2("destroy");
        // $(".select2-custom-2").select2("destroy");
        // $(".select2-custom-hotel").select2("destroy");
        let orgHtml = document.querySelector(
          `div[data-build-id='${buildId}']`
        ).innerHTML;

        // replace
        orgHtml = orgHtml.replaceAll(`data-is-org="${buildId}"`, "");
        orgHtml = orgHtml.replaceAll();
        customHtml = orgHtml
          .replaceAll("_0_", `_${time}_`)
          .replaceAll("[0]", `[${time}]`);
        //
      }

      // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
      let linkId = link.dataset.id;
      // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
      let regexp = linkId ? new RegExp(linkId, "g") : null;
      // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
      let newFields = customHtml
        ? customHtml
        : regexp
        ? link.dataset.fields.replace(regexp, time)
        : null;
      // Add the new markup to the form if there are fields to add.
      newFields ? link.insertAdjacentHTML("beforebegin", newFields) : null;

      window.App.initButtonRemoveField();
      window.App.countAndCheckNumberUploadImage();
      window.App.initEventUpload();
      window.App.countNumberQuestions();
      window.App.initEventRadioQuestions();
      window.App.initEventInputChoices();
      window.App.initEventRadioSelectOption();
      window.App.initButtonAddField();
      window.App.initYoutube();
    });
  }

  /**
   * When a user clicks on a link with the class `remove_fields`, find the closest parent element with
   * the class `nested-fields`, find the hidden delete field, update the value to `1` and hide the
   * corresponding nested fields
   */
  initButtonRemoveField() {
    document.querySelectorAll(".remove_fields").forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        // Find the parent wrapper for the set of nested fields.
        let fieldParent = link.closest(".nested-fields");
        // If there is a parent wrapper, find the hidden delete field.
        let deleteField = fieldParent
          ? fieldParent.querySelector('input[type="hidden"]')
          : null;
        // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
        if (deleteField) {
          deleteField.value = 1;
        }
        fieldParent.style.display = "none";
        fieldParent.setAttribute("is_remove", 1);
        this.countAndCheckNumberUploadImage();
        this.countNumberQuestions();
      });
    });
  }

  /**
   * It fetches more projects from the server and appends them to the page
   */
  initButtonReadMorePrjDraft() {
    const button = document.querySelector("#read_more_prj_draft");
    const divContentReadMore = document.querySelector(
      "#content_read_more_prj_draft"
    );
    if (button) {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        try {
          const currentPage = button.getAttribute("data-page");
          button.disabled = true;
          Overlay.show();
          // Fetch projects_draft
          const res = await fetch(
            "/users/projects/read_more_project?status=draft&page=" + currentPage
          );
          const data = await res.text();
          const oldHtml = divContentReadMore.innerHTML;
          divContentReadMore.innerHTML = oldHtml + data;
          // Check can read more
          const canNotReadMore = document.querySelector(
            '[data-can-read-more-draft="false"]'
          );
          if (canNotReadMore) {
            button.classList.add("d-none");
          } else {
            button.setAttribute("data-page", parseInt(currentPage) + 1);
          }
        } catch (error) {
          console.log(error);
        } finally {
          button.disabled = false;
          Overlay.hide();
        }
      });
    }
  }

  /**
   * It fetches more projects from the server and appends them to the page
   */
  initButtonReadMorePrjReviewing() {
    const button = document.querySelector("#read_more_prj_reviewing");
    const divContentReadMore = document.querySelector(
      "#content_read_more_prj_reviewing"
    );
    if (button) {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        try {
          const currentPage = button.getAttribute("data-page");
          button.disabled = true;
          Overlay.show();
          // Fetch projects_draft
          const res = await fetch(
            "/users/projects/read_more_project?status=reviewing&page=" +
              currentPage
          );
          const data = await res.text();
          const oldHtml = divContentReadMore.innerHTML;
          divContentReadMore.innerHTML = oldHtml + data;
          // Check can read more
          const canNotReadMore = document.querySelector(
            '[data-can-read-more-reviewing="false"]'
          );
          if (canNotReadMore) {
            button.classList.add("d-none");
          } else {
            button.setAttribute("data-page", parseInt(currentPage) + 1);
          }
        } catch (error) {
          console.log(error);
        } finally {
          button.disabled = false;
          Overlay.hide();
        }
      });
    }
  }

  /**
   * It fetches more projects from the server and appends them to the DOM
   */
  initButtonReadMorePrjReJected() {
    const button = document.querySelector("#read_more_prj_rejected");
    const divContentReadMore = document.querySelector(
      "#content_read_more_prj_rejected"
    );
    if (button) {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        try {
          const currentPage = button.getAttribute("data-page");
          button.disabled = true;
          Overlay.show();
          // Fetch projects_draft
          const res = await fetch(
            "/users/projects/read_more_project?status=rejected&page=" +
              currentPage
          );
          const data = await res.text();
          const oldHtml = divContentReadMore.innerHTML;
          divContentReadMore.innerHTML = oldHtml + data;
          // Check can read more
          const canNotReadMore = document.querySelector(
            '[data-can-read-more-rejected="false"]'
          );
          if (canNotReadMore) {
            button.classList.add("d-none");
          } else {
            button.setAttribute("data-page", parseInt(currentPage) + 1);
          }
        } catch (error) {
          console.log(error);
        } finally {
          button.disabled = false;
          Overlay.hide();
        }
      });
    }
  }

  /**
   * It fetches more projects from the server and appends them to the page
   */
  initButtonReadMorePrjWaitingPublic() {
    const button = document.querySelector("#read_more_prj_waiting_public");
    const divContentReadMore = document.querySelector(
      "#content_read_more_prj_waiting_public"
    );
    if (button) {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        try {
          const currentPage = button.getAttribute("data-page");
          button.disabled = true;
          Overlay.show();
          // Fetch projects_draft
          const res = await fetch(
            "/users/projects/read_more_project?status=waiting_public&page=" +
              currentPage
          );
          const data = await res.text();

          const oldHtml = divContentReadMore.innerHTML;
          divContentReadMore.innerHTML = oldHtml + data;
          // Check can read more
          const canNotReadMore = document.querySelector(
            '[data-can-read-more-waiting-public="false"]'
          );
          if (canNotReadMore) {
            button.classList.add("d-none");
          } else {
            button.setAttribute("data-page", parseInt(currentPage) + 1);
          }
        } catch (error) {
          console.log(error);
        } finally {
          button.disabled = false;
          Overlay.hide();
        }
      });
    }
  }

  /**
   * It fetches more projects from the server and appends them to the page
   */
  initButtonReadMorePrjPublishedInprogress() {
    const button = document.querySelector(
      "#read_more_prj_published_inprogress"
    );
    const divContentReadMore = document.querySelector(
      "#content_read_more_prj_published_inprogress"
    );
    if (button) {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        try {
          const currentPage = button.getAttribute("data-page");
          button.disabled = true;
          Overlay.show();
          // Fetch projects_draft
          const res = await fetch(
            "/users/projects/read_more_project?status=published_inprogress&page=" +
              currentPage
          );
          const data = await res.text();

          const oldHtml = divContentReadMore.innerHTML;
          divContentReadMore.innerHTML = oldHtml + data;
          // Check can read more
          const canNotReadMore = document.querySelector(
            '[data-can-read-more-published-inprogress="false"]'
          );
          if (canNotReadMore) {
            button.classList.add("d-none");
          } else {
            button.setAttribute("data-page", parseInt(currentPage) + 1);
          }
        } catch (error) {
          console.log(error);
        } finally {
          button.disabled = false;
          Overlay.hide();
        }
      });
    }
  }

  /**
   * It fetches more projects from the server and appends them to the page
   */
  initButtonReadMorePrjPublishedDone() {
    const button = document.querySelector("#read_more_prj_published_done");
    const divContentReadMore = document.querySelector(
      "#content_read_more_prj_published_done"
    );
    if (button) {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        try {
          const currentPage = button.getAttribute("data-page");
          button.disabled = true;
          Overlay.show();
          // Fetch projects_draft
          const res = await fetch(
            "/users/projects/read_more_project?status=published_done&page=" +
              currentPage
          );
          const data = await res.text();

          const oldHtml = divContentReadMore.innerHTML;
          divContentReadMore.innerHTML = oldHtml + data;
          // Check can read more
          const canNotReadMore = document.querySelector(
            '[data-can-read-more-published-done="false"]'
          );
          if (canNotReadMore) {
            button.classList.add("d-none");
          } else {
            button.setAttribute("data-page", parseInt(currentPage) + 1);
          }
        } catch (error) {
          console.log(error);
        } finally {
          button.disabled = false;
          Overlay.hide();
        }
      });
    }
  }

  /**
   * It fetches more projects from the server and appends them to the page
   */
  initButtonReadMorePrjCompletedInprogress() {
    const button = document.querySelector(
      "#read_more_prj_completed_inprogress"
    );
    const divContentReadMore = document.querySelector(
      "#content_read_more_prj_completed_inprogress"
    );
    if (button) {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        try {
          const currentPage = button.getAttribute("data-page");
          button.disabled = true;
          Overlay.show();
          // Fetch projects_draft
          const res = await fetch(
            "/users/projects/read_more_project?status=completed_inprogress&page=" +
              currentPage
          );
          const data = await res.text();

          const oldHtml = divContentReadMore.innerHTML;
          divContentReadMore.innerHTML = oldHtml + data;
          // Check can read more
          const canNotReadMore = document.querySelector(
            '[data-can-read-more-completed-inprogress="false"]'
          );
          if (canNotReadMore) {
            button.classList.add("d-none");
          } else {
            button.setAttribute("data-page", parseInt(currentPage) + 1);
          }
        } catch (error) {
          console.log(error);
        } finally {
          button.disabled = false;
          Overlay.hide();
        }
      });
    }
  }

  /**
   * It fetches more projects from the server and appends them to the page
   */
  initButtonReadMorePrjCompletedDone() {
    const button = document.querySelector("#read_more_prj_completed_done");
    const divContentReadMore = document.querySelector(
      "#content_read_more_prj_completed_done"
    );
    if (button) {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        try {
          const currentPage = button.getAttribute("data-page");
          button.disabled = true;
          Overlay.show();
          // Fetch projects_draft
          const res = await fetch(
            "/users/projects/read_more_project?status=completed_done&page=" +
              currentPage
          );
          const data = await res.text();

          const oldHtml = divContentReadMore.innerHTML;
          divContentReadMore.innerHTML = oldHtml + data;
          // Check can read more
          const canNotReadMore = document.querySelector(
            '[data-can-read-more-completed-done="false"]'
          );
          if (canNotReadMore) {
            button.classList.add("d-none");
          } else {
            button.setAttribute("data-page", parseInt(currentPage) + 1);
          }
        } catch (error) {
          console.log(error);
        } finally {
          button.disabled = false;
          Overlay.hide();
        }
      });
    }
  }

  /**
   * It counts the number of uploaded images and checks if the number of uploaded images is greater than
   * or equal to the maximum number of images allowed to upload. If it is, it hides the button to add new
   * images. Otherwise, it shows the button to add new images
   */
  countAndCheckNumberUploadImage() {
    const buttonAddNew = document.querySelector(".add_fields");
    const fieldUploadImages = document.querySelectorAll(".nested-fields");
    let numberDisplay = 0;
    fieldUploadImages.forEach((field) => {
      if (field.getAttribute("is_remove") == 0) {
        numberDisplay++;
      }
    });
    if (numberDisplay >= MAX_ITEM_UPLOAD) {
      buttonAddNew.classList.add("d-none");
    } else {
      buttonAddNew.classList.remove("d-none");
    }
  }

  /**
   * It adds an event listener to each radio button, and when the radio button is clicked, it checks the
   * value of the radio button and enables or disables the target amount input field
   */
  initRadioButtonTargetAmountChange() {
    const radioButtons = document.getElementsByName(
      "project[has_target_amount]"
    );
    radioButtons.forEach((radioButton) => {
      radioButton.addEventListener("change", (event) => {
        const targetAmount = document.querySelector("#project_target_amount");
        if (radioButton.value === "true") {
          targetAmount.disabled = false;
        } else {
          targetAmount.disabled = true;
        }
      });
    });
  }

  /**
   * It's a function that when the user clicks on the button "Add new report", it will render the form to
   * add a new report
   */
  initButtonAddNewReport() {
    const button = document.querySelector("#add_new_report");
    const divContent = document.querySelector("#content_report");
    const divComment = document.querySelector("#content_report_list");
    if (button) {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        try {
          const projectId = document.querySelector("#project_id").value;
          const res = await fetch(
            "/users/reports/render_html_add_new_report?project_id=" + projectId
          );
          const data = await res.text();
          divContent.innerHTML = data;
          divComment.classList.add("d-none");
          divContent.classList.remove("d-none");
          this.initEventUpload();
          // Cancel add new report
          const buttonCancel = document.querySelector("#btn_cancel");
          if (buttonCancel) {
            buttonCancel.addEventListener("click", (e) => {
              e.preventDefault();
              divContent.classList.add("d-none");
              divComment.classList.remove("d-none");
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
  }

  /**
   * It's a function that when the user clicks on the button "Add new comment", it will render the form
   * to add a new comment
   */
  initButtonAddNewComment() {
    const button = document.querySelector("#add_new_comment");
    const divContent = document.querySelector("#content_new_comment");
    const divComment = document.querySelector("#content_comment_list");
    if (button) {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        try {
          const projectId = document.querySelector("#project_id").value;
          const res = await fetch(
            "/users/comments/render_html_add_new_comment?project_id=" +
              projectId
          );
          const data = await res.text();
          divContent.innerHTML = data;
          divComment.classList.add("d-none");
          divContent.classList.remove("d-none");
          this.initEventUpload();
          // Cancel add new comment
          const buttonCancel = document.querySelector("#btn_cancel");
          if (buttonCancel) {
            buttonCancel.addEventListener("click", (e) => {
              e.preventDefault();
              divContent.classList.add("d-none");
              divComment.classList.remove("d-none");
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
  }

  activeTabPage(tab) {
    switch (tab) {
      case "tab_supporter":
        document.querySelector(".tab_supporter").click();
        break;
      case "tab_comment":
        document.querySelector(".tab_comment").click();
        break;
      case "tab_active_report":
        document.querySelector(".tab_active_report").click();
        break;
      case "tab_messages":
        document.querySelector(".tab_messages").click();
        break;
      case "tab_rejected":
        document.querySelector(".tab_rejected").click();
        break;
      case "tab_draft":
        document.querySelector(".tab_draft").click();
        break;
      default:
        break;
    }
  }

  initEventDeleteReport() {
    const buttons = document.querySelectorAll(".btn_delete_report");
    buttons.forEach((button) => {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        if (window.confirm("活動レポートを削除しますか？")) {
          try {
            const reportId = button.getAttribute("data-report-id");
            const res = await fetch(
              "/users/reports/delete_report?report_id=" + reportId
            );
            const data = await res.json();
            if (data.status === "success") {
              window.redirectPage.go(window.location.href, function () {
                window.App.activeTabPage("tab_active_report");
                window.notyf.success({
                  message: "活動レポートを削除しました",
                });
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    });
  }

  initAddQuestion() {
    $(document).on("click", ".add-question", function (e) {
      $(
        `<a class="remove-question text-link ms-5 position-absolute click-add-remove" data-reward="${e.currentTarget.getAttribute(
          "data-reward"
        )}" data-choice="${e.currentTarget.getAttribute(
          "data-choice"
        )}" data-index="${e.currentTarget.getAttribute(
          "data-index"
        )}">✕</a><div class="m-5 question-${e.currentTarget.getAttribute(
          "data-reward"
        )}-${e.currentTarget.getAttribute(
          "data-choice"
        )}-${e.currentTarget.getAttribute(
          "data-index"
        )}"><span class="m-4">選択肢</span><input class="form-control input_choices input-validate input_choice" placeholder="質問を入してください" multiple="multiple" value="" data-validate-name= "rewards.reward_questions.choices" type="text"></div>`
      ).insertBefore(
        "#answer-add-" +
          e.currentTarget.getAttribute("data-reward") +
          "-" +
          e.currentTarget.getAttribute("data-choice") +
          ""
      );
      e.currentTarget.dataset.index =
        parseInt(e.currentTarget.getAttribute("data-index")) + 1;
      window.App.initEventInputChoices();
    });
  }

  initRemoveQuestion() {
    $(document).on("click", ".remove-question", function (e) {
      e.preventDefault;
      $(
        ".question-" +
          e.currentTarget.getAttribute("data-reward") +
          "-" +
          e.currentTarget.getAttribute("data-choice") +
          "-" +
          e.currentTarget.getAttribute("data-index") +
          ""
      ).remove();
      const inputHidden = e.currentTarget
        .closest(".content_choices")
        .querySelector(".choices_hidden");
      const inputChoices = e.currentTarget
        .closest(".content_choices")
        .querySelectorAll(".input_choice");
      let arrayChoices = [];
      inputChoices.forEach((e) => {
        if (e.value) arrayChoices.push(e.value);
      });
      inputHidden.value = arrayChoices.join(",");
      e.currentTarget.remove();
    });
  }

  initEventDeleteComment() {
    const buttons = document.querySelectorAll(".btn_delete_comment");
    buttons.forEach((button) => {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        if (window.confirm("応援コメントを削除しますか？")) {
          try {
            const commentId = button.getAttribute("data-comment-id");
            const res = await fetch(
              "/users/comments/delete_comment?comment_id=" + commentId
            );
            const data = await res.json();
            if (data.status === "success") {
              window.redirectPage.go(window.location.href, function () {
                window.App.activeTabPage("tab_comment");
                window.notyf.success({
                  message: "応援コメントを削除しました",
                });
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    });
  }

  showMessageDetail() {
    const message_items = document.querySelectorAll(".message-item");
    const sectionMessage = document.querySelector("#section-message");
    const sectionListMessage = document.querySelector("#section-list-message");
    const messageList = document.querySelector("#messages-section");
    const targetUserId = document.querySelector("#target_user_id");
    message_items.forEach((message_item) => {
      message_item.addEventListener("click", async (event) => {
        event.preventDefault();
        sectionListMessage.classList.add("d-none");
        sectionListMessage.classList.remove("d-block");
        try {
          const userId = message_item.getAttribute("data-user-id");
          const projectId = message_item.getAttribute("data-project-id");
          targetUserId.value = userId;
          const res = await fetch(
            `/users/projects/${projectId}/messages?user_id=${userId}`
          );
          const data = await res.text();
          messageList.innerHTML = data;
          $("#messages-section").scrollTop(
            $("#messages-section").prop("scrollHeight")
          );
          sectionMessage.classList.add("d-block");
          sectionMessage.classList.remove("d-none");
        } catch (error) {
          console.log(error);
        }
      });
    });
  }

  showMessageList() {
    const buttonMessage = document.querySelector("#back-message-list");
    const sectionMessage = document.querySelector("#section-message");
    const sectionListMessage = document.querySelector("#section-list-message");
    const messageList = document.querySelector("#messages-section");
    buttonMessage.addEventListener("click", (event) => {
      event.preventDefault();
      sectionListMessage.classList.add("d-block");
      sectionListMessage.classList.remove("d-none");
      sectionMessage.classList.add("d-none");
      sectionMessage.classList.remove("d-block");
      messageList.innerHTML = "";
    });
  }

  showSupporters() {
    $("#reward-select").on("change", function() {
      const devSupporterReward = document.querySelectorAll(".supporter-reward")
      if ($(this).val() == "") {
        devSupporterReward.forEach((item) => {item.classList.remove("d-none"); item.classList.add("d-block");});
      } else {
        devSupporterReward.forEach((item) => {item.classList.remove("d-block"); item.classList.add("d-none");});
        document.querySelectorAll(".supporter-reward-" + $(this).val()).forEach((item) => item.classList.add("d-block"));
      }
    });
  }

  initYoutube() {
    console.log(1)
    const input_youtubes = document.querySelectorAll(".input_youtube");
    input_youtubes.forEach((input) => {
      input.addEventListener("input", async (event) => {
        const elementPrarent = input.closest(".content-upload");
        const value = event.target.value;
        const res = await fetch(
          "/users/projects/youtube_info?url=" +
            value
        );
        const data = await res.json();
        if (data.status == 200) {
          input.classList.remove("input-validate", "is-invalid", "input-error");
          elementPrarent
            .querySelector(".invalid-feedback")
            ?.remove();
          elementPrarent
            .querySelector(".content_show_file")
            ?.classList.remove("d-none");
          elementPrarent
            .querySelector(".show-image")
            ?.setAttribute("src", data.data);
          elementPrarent
            .querySelector(".show-video")
            ?.classList.add("d-none");
          elementPrarent.querySelector(".file_url").value = data.data;
        } else {
          input.classList.remove("input-validate", "is-invalid", "input-error");
          elementPrarent
            .querySelector(".invalid-feedback")
            ?.remove();
          input.classList.add("input-validate", "is-invalid", "input-error");
          const errorMessage = document.createElement("div");
          errorMessage.classList.add("invalid-feedback", "text-red-6");
          errorMessage.innerHTML = data.message;
          elementPrarent
            .querySelector(".form-input-file")
            .appendChild(errorMessage);
        }
      });
    });
  }

  slideInit() {
    $(".slider-for").slick({
      autoplay: true,
      dots: false,
      arrows: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1
    });
    $('.slideThumb').on('click', function() {
      var index = $(this).attr('data-index');
      $('.slider-for').slick('slickGoTo', index, false);
    });
  }
}
