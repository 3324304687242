import { Base } from "./base";

export class SupporterProfiles extends Base {
  show() {
    console.log("SupporterProfiles show");
    this.initButtonReadMore();
  }

  initButtonReadMore() {
    const buttons = document.querySelectorAll(".btn_read_more");
    buttons.forEach((button) => {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        try {
          const target = button.getAttribute("target");
          button.disabled = true;
          Overlay.show();
          const res = await fetch(
            "/supporter_profiles/read_more_project?target=" +
              target +
              "&user_id=" +
              document.getElementById("user_id").value
          );
          const data = await res.text();
          if (target === "implemented_projects") {
            const div = document.getElementById("list_implemented_projects");
            div.innerHTML = data;
          } else {
            const div = document.getElementById("list_supported_projects");
            div.innerHTML = data;
          }
          button.classList.add("d-none");
        } catch (e) {
          console.log(e);
        } finally {
          Overlay.hide();
          button.disabled = false;
        }
      });
    });
  }
}
