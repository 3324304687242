import { Base } from "./base";
import Rails from "@rails/ujs";
import { Carousel } from "bootstrap";

export class Projects extends Base {
  show() {
    this.initButtonAddFavorite();
    this.initButtonRemoveFavorite();
    this.slideInit();
    this.initButtonAddNewComment();
    this.initEventDeleteComment();
  }

  preview() {
    this.slideInit();
    document.querySelectorAll(".btn").forEach((btn) => {
      btn.disabled = true;
      btn.addEventListener("click", (e) => {
        e.preventDefault();
      });
    });
    document.querySelectorAll("a").forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
      });
    });
  }

  frame() {
    this.slideInit();
    document.querySelector("header").classList.add("d-none");
    document.querySelector(".jumps-prevent").classList.add("d-none");
    document.querySelector("footer").classList.add("d-none");
    document.querySelectorAll(".btn").forEach((btn) => {
      btn.disabled = true;
      btn.addEventListener("click", (e) => {
        e.preventDefault();
      });
    });
    document.querySelectorAll("a").forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
      });
    });
    document.querySelector("#back-to-top").classList.add("d-none");
    document.querySelector("#drafter-info").classList.add("d-none");
  }

  slideInit() {
    $(".slider-for").slick({
      autoplay: true,
      dots: false,
      arrows: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1
    });
    $('.slideThumb').on('click', function() {
      var index = $(this).attr('data-index');
      $('.slider-for').slick('slickGoTo', index, false);
    });
  }

  initButtonAddFavorite() {
    const button = document.querySelector("#btn_add_favorite");
    if (button) {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        const url = "/users/favorites";
        const res = await fetch(url, {
          method: "POST",
          body: JSON.stringify({
            project_id: button.getAttribute("data-target"),
          }),
          headers: {
            "X-CSRF-Token": Rails.csrfToken(),
            "Content-Type": "application/json",
          },
        });
        const data = await res.json();
        if (data && data.success) {
          button.classList.add("d-none");
          document
            .querySelector(".btn_added_favorite")
            .classList.remove("d-none");
          window.notyf.success({
            message: "おきに入りに追加しました！",
          });
        } else {
          window.notyf.error({
            message: "おきに入りに追加できませんでした！",
          });
        }
      });
    }
  }

  initButtonRemoveFavorite() {
    const button = document.querySelector("#btn_remove_favorite");
    if (button) {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        const url = "/users/favorites/remove";
        const res = await fetch(url, {
          method: "POST",
          body: JSON.stringify({
            project_id: button.getAttribute("data-target"),
          }),
          headers: {
            "X-CSRF-Token": Rails.csrfToken(),
            "Content-Type": "application/json",
          },
        });
        const data = await res.json();
        if (data && data.success) {
          button.classList.add("d-none");
          document
            .querySelector("#btn_add_favorite")
            .classList.remove("d-none");
          window.notyf.success({
            message: "おきに入りから削除しました！",
          });
        } else {
          window.notyf.error({
            message: "おきに入りから削除できませんでした！",
          });
        }
      });
    }
  }

  initButtonAddNewComment() {
    const button = document.querySelector("#add_new_comment");
    const divContent = document.querySelector("#content_new_comment");
    const divComment = document.querySelector("#content_comment_list");
    if (button) {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        try {
          const projectId = document.querySelector("#project_id").value;
          const res = await fetch(
            "/comments/render_html_add_new_comment?project_id=" + projectId
          );
          const data = await res.text();
          divContent.innerHTML = data;
          divComment.classList.add("d-none");
          divContent.classList.remove("d-none");
          // this.initEventUpload();
          // Cancel add new comment
          const buttonCancel = document.querySelector("#btn_cancel");
          console.log("button_cancel")
          if (buttonCancel) {
            buttonCancel.addEventListener("click", (e) => {
              e.preventDefault();
              divContent.classList.add("d-none");
              divComment.classList.remove("d-none");
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
  }
  initEventDeleteComment() {
    const buttons = document.querySelectorAll(".btn_delete_comment");
    buttons.forEach((button) => {
      button.addEventListener("click", async (event) => {
        event.preventDefault();
        if (window.confirm("応援コメントを削除しますか？")) {
          try {
            const commentId = button.getAttribute("data-comment-id");
            const res = await fetch(
              "/comments/delete_comment?comment_id=" + commentId
            );
            const data = await res.json();
            if (data.status === "success") {
              window.redirectPage.go(window.location.href, function () {
                window.App.activeTabPage("tab_comment");
                window.notyf.success({
                  message: "応援コメントを削除しました",
                });
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    });
  }

  activeTabPage(tab) {
    switch (tab) {
      case "tab_comment":
        document.querySelector(".tab_comment").click();
        break;
      case "tab_active_report":
        document.querySelector(".tab_active_report").click();
        break;
      default:
        break;
    }
  }
}
